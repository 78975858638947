/*
  ERROR PAGE TEMPLATE
  A template to display 404 pages
*/

import React from 'react'

import PostContextProvider from '@base/utils/contexts/posts-context'
import PageLayout from '@base/layout/page-layout'
import Button from '@base/parts/button'

import { getGeneralData } from '@api/general-data'
import hrv from '@img/MyM_HRV.png'

const ErrorPage = ({ pageContext }) => {
  if (!pageContext) {
    return null
  }

  const data = {
    pageData: {
      ...pageContext.content
    }
  }

  // Setup Page specific data
  const generalData = getGeneralData(pageContext, data)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const title = data.pageData.title
  const parentPage = null
  const disablePageBreadcrumbs = true
  const theme = pageContext.siteTheme
  const contentStyle = theme !== null && theme.content && theme.content.ErrorPage ? theme.content.ErrorPage : null
  const content = data.pageData

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs: disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: { back: globalData.stringsActions.back, home: globalData.stringsSlugs.homeTitle }
      }}
    >
      <PostContextProvider>
        <section className="errors relative">
          <div className="errors-bg z-under pointer-events-none"></div>
          <div className="container flex lg:flex-row justify-center items-center pt-24 lg:pt-96 pb-16 lg:pb-[34rem] z-1 max-w-[125rem]">
            <img src={content?.img ? content.img : hrv} className="hidden lg:block w-[30rem]" />
            <div className="errors-content flex items-center flex-col">
              <h1 className={contentStyle !== null ? `${contentStyle.title}` : ''}>
                {content?.title ? (content.title) : ('Not found')}
              </h1>
              <img src={content?.img ? content.img : hrv} className="block lg:hidden py-8 w-64" />
              <p className={contentStyle !== null ? `h3 lg:text-xl ${contentStyle.text}` : 'h3 lg:text-xl'}>
                {content?.text ? (content.text) : ('The page you are looking for does not exists or has been moved.')}
              </p>
              <Button
                label={content?.button ? (content.button) : ('Back to home page')}
                link={pageContext.locale !== 'en' ? (`/${pageContext.locale}`) : ('/')}
                trigger="internalLink"
                style="secondary"
                size="lg"
                pageContext={pageContext}
                overwrite={true}
                className={contentStyle !== null && contentStyle.button ? contentStyle.button : 'btn button button-narrow items-center justify-center w-auto relative z-10 mt-6 flex font-normal text-lg normal-case cursor-pointer'}
              />
            </div>
          </div>
        </section>
      </PostContextProvider>
    </PageLayout>
  )
}

export default ErrorPage

export { Head } from '@base/layout/head'